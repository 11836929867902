.widget-btn {
    position: absolute !important;
    bottom: 30px;
    left: 15px;
    background-color: #649291;
    border-radius: 20px;
}

.widget-expanded {
    border-top: 1px solid white;
}

.widget-btn button {
    height: 40px;
    width: 40px;
    padding: 0;
    padding-bottom: 3px;
    color: white !important;
}

.widget-btn > button {
    border-radius: 20px;
}

.first-button {
    border-radius: 20px 20px 0 0 !important;
}

.last-button {
    border-radius: 0 0 20px 20px !important;
}

.widget-btn button:hover {
    background-color: #73BCBC !important;
    border: 1px solid #1890FF !important;
}

.rectangle {
    margin-left: 6px;
    height: 20px;
    width: 26px;
    border: 2px solid white;
    background-color: rgba(255,255,255,.4);
    font-size: 12px;
}

.close-widgets-btn {
    position: absolute !important;
    bottom: -20px;
    left: 40px;
    color: white !important;
    background-color: #649291 !important;
    height: 25px !important;
}

.close-widgets-btn:hover {
    background-color: #73BCBC !important;
    border-color: #1890FF !important;
}

.hoh-container {
    border-top: 1px solid #e2e2e2;
    padding: .5rem .25rem;
    margin: 0 .75rem;
}

.share-container {
    /* border: 1px solid #e2e2e2; */
    padding: .5rem .25rem !important;
    margin: 0 .75rem;
    background-color: #fff;
    position: absolute !important;
    bottom: 0px;
    left: 40px;
    color: #000000 !important;
    /* background-color: #649291 !important; */
    /* height: 25px !important; */
    width: 400px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}

.share-container-input {
    border: 1px solid #649291;
    padding: .5rem .25rem !important;
    margin: 0 .75rem;
    background-color: #fff;
    width: 80%;
}

.share-container-text {
    padding: .5rem .25rem !important;
    margin: 0 .75rem;
}

.share-container-copytext {
    margin: 0;
    background-color: #fff !important;
    color: #649291 !important;
    text-align: center;
    font-weight: bold;
    font-size: 12px;
    float: right;
}

.copy-share-btn {
    padding: .5rem .25rem !important;
    color: white !important;
    background-color: #649291 !important;
}

.copy-share-btn:hover {
    background-color: #73BCBC !important;
    border-color: #1890FF !important;
}

.widget-help {
    position: absolute;
    bottom: 0;
    left: 48px;
    width: 25vw;
    max-height: 25vh;
    overflow: auto;
}