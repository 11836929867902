.timeline-container {
    margin: 1rem 0;
    text-align: center;
    max-width: 100%;
    overflow: auto;
}

.timeline-table {
    width: 100%;
    min-width: 575px;
}

.timeline-table td {
    height: 30px;
    width: 9%;
}

.center-line {
    border-top: 10px solid #E4E4E4;
}

.border-right {
    border-right: 10px solid #E4E4E4;
}

.border-left {
    border-left: 10px solid #E4E4E4;
}

.period-name {
    text-align: center;
    color: #0C7978;
    font-weight: 600;
    font-size: 16px;
    word-break: break-word;
}

.period-name a:hover {
    text-decoration: underline;
}

.period-years {
    text-align: center;
    color: #737373;
    font-weight: 400;
    font-size: 12px;
}

/* Responsiveness */
@media only screen and (max-width: 1050px) {
    .timeline-container {
        margin: 1rem 0;
    }
    .period-name {
        font-size: 13px;
    }
}