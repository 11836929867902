.left-menu {
    flex: 0 0 300px;
    height: 100%;
    overflow: hidden;
    border-right: 1px solid #D8D8D8;
    background-color: white;
    z-index: 1001; /* Must be > map layers btn*/
}

.left-menu-content {
    height: calc(100% - 90px);
    margin-top: 90px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.search-content {
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.search-content-top {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.move-left {
    margin-left: -300px;
}

.drawer-btn {
    z-index: 1000;
    position: absolute !important;
    top: 65px;
    left: 0;
    height: 90px !important;
    padding: 0 5px !important;
    border: none !important;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25) !important;
    background-color: rgba(255,255,255,0.7) !important;
    border-radius: 0 2px 2px 0;
}

.drawer-open {
    left: 300px !important;
    top: 0 !important;
}

.btn-icon {
    height: 20px;
}

.result-type-btn {
    font-size: 12px;
    color: black;
}

.selected-result-base {
    font-weight: bold;
}

.selected-result-base span {
    border-bottom: 3px solid #0C7978;
}

.searchbar {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    padding: 1rem 0 1rem .5rem;
}

i.anticon-search {
    display: none;
}

.searchbar-input {
    width: 252px !important;
}

.search-help {
    background-color: #E0EFEF;
    border-radius: 5px;
    font-size: 14px;
    color: black;
    margin: 0 10px 10px 10px;
    padding: 7px;
}

.search-help > div {
    margin-bottom: 5px;
}

.search-help-header {
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0 !important;
}

.museumno-input,
.adm-input {
    float: right;
}

.museumno-input {
    width: 130px !important;
    margin-top: -5px;
}

.adm-input {
    width: 160px !important;
    margin-top: -8px;
}

.museumno-input .ant-input-affix-wrapper,
.adm-input .ant-input-affix-wrapper {
    padding: 4px 5px;
}

.museumno-input .ant-input-suffix,
.adm-input .ant-input-suffix {
    margin-left: 0;
}

/* Responsiveness */
@media only screen and (max-width: 1000px) {
    .left-menu-content {
        height: calc(100% - 60px);
        margin-top: 60px;
    }
    .drawer-btn {
        top: 60px;
    }
}
@media only screen and (max-width: 450px) {
    .drawer-btn {
        left: 0;
    }
    .drawer-open {
        top: 40px !important;
        left: 275px !important;
    }
    .searchbar {
        flex-grow: 1;
    }
    .search-help {
        right: 1rem;
        width: auto;
        height: 75%;
    }
}