.user-btn {
    background: transparent !important;
    padding-left: 5px !important;
    color: #ffffff !important;
    max-width: 175px;
}

.user-btn span {
    max-width: 155px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.user-menu {
    position: absolute;
    top: 2rem;
    left: 1rem;
    background-color: white;
    color: black;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
    font-size: 14px;
    text-align: left;
    z-index: 1002;
}

.user-menu div {
    margin: .5rem 1rem;
}

/* Responsiveness */
@media only screen and (max-width: 450px) {
    .user-menu {
        left: auto;
        right: .5rem;
    }
}