.details-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.details-header {
    text-align: center !important;
}

.details-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    overflow: auto;
}

.details-form {
    width: 100%;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
}

.details-objekt-header {
    position: relative;
    border-bottom: 4px solid #E0EFEF;
    font-size: 20px;
    color: #171717;
    line-height: 27px;
    text-align: center;
    padding: 1rem;
}

.timeline-btn-container {
    position: absolute;
    top: 14px;
}

.details-objekt-download {
    position: absolute;
    top: 14px;
    right: 14px;
}

.details-objekt-subheader {
    font-size: 14px;
    line-height: 19px;
    margin-top: 8px;
}

.details-objekt-subheader a:hover {
    text-decoration: underline;
}

.details-subheader,
.details-subheader button {
    font-size: 20px;
    line-height: 27px;
    color: black;
    text-align: left;
    margin: .5rem 0 .25rem 0;
    font-weight: bold;
    padding: 0; 
}

.details, .details-description {
    font-size: 16px;
    line-height: 21px;
    color: black;
    text-align: left;
}

.details-description {
    padding: 0 3rem;
    width: 60%;
}

.details-description > button {
    float: right;
}

.details-table-wrapper {
    display: flex;
    justify-content: center;
}

.details-table-container {
    margin: 1rem 0 2rem 0;
    background-color: #F7FAFA;
    width: 100%;
}

.w60p {
    width: 60% !important;
}

.details-table-container-header {
    padding: 0 3rem;
    height: 50px;
    color: #0C7978;
    font-weight: bold;
    font-size: 20px;
    background-color: #E0EFEF;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.white {
    background-color: white !important;
    justify-content: flex-start;
}

.details-table-container-header button {
    font-size: 20px;
    height: 40px;
}

.details-table-container-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.centered {
    justify-content: center;
}

.details-table {
    width: 100%;
}

.centered > .details-table {
    width: 60%;
}

.centered > .details-table .details-key {
    padding-left: 3rem;
}
.centered > .details-table .details-value {
    padding-right: 3rem;
}

.details-table tr:nth-child(even),
.datering-table tbody tr:nth-child(even) {
    background-color: #EDF5F5;
}

.details-key, .details-value {
    font-size: 13px;
    line-height: 17px;
    color: black;
    text-align: left;
    padding: .4rem 1rem;
    white-space: normal;
    word-break: break-all;
}

.details-key {
    font-weight: bold;
    width: 30%;
}

.detail-value {
    width: 70%;
}

.details-link {
    text-align: left;
}

.details-link a {
    color: black;
    font-weight: bold;
    font-size: 16px;
    padding-left: 0;
}

.details-link a:hover {
    color: #1890FF;
}

.details-related-info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem 0;
}

.details-related-info-container > div {
    width: 60%;
}

.details-related-info-container-content {
    background-color: #F7FAFA;
    padding: 1rem 3rem;
}

.details-images {
    margin-top: 1rem;
    text-align: center;
}

.object-link {
    cursor: pointer;
    margin: .5rem 1.5rem;
    width: 80%;
    text-align: left !important;
}

.no-geometry{
    margin: 1rem;
    font-size: 13px;
    font-style: italic;
    font-weight: 600;
    color: #06504F;
}

.timeline-btn {
    border-color: #73BCBC !important;
    padding: 0 10px !important;
    color: #0C7978 !important;
}

.timeline-btn:hover {
    color: #1890FF !important;
    background-color: #E0EFEF !important;
}

.timeline-btn span {
    font-size: 14px;
    font-weight: 600;
    margin-left: 10px;
}

.shown {
    background-color: #73BCBC !important;
}

.shown span {
    color: white !important;
}

.datering-container {
    margin: 1rem 0;
    width: auto;
    overflow: auto;
}

.datering-table {
    margin-top: 1rem;
    width: 100%;
    min-width: 400px;
    font-size: 13px;
}

.datering-table thead tr {
    background-color: #E0EFEF;
}

.datering-table th, .datering-table td {
    padding: .4rem 1rem;
}

.relation-single-object {
    border-bottom: 2px solid #73BCBC;
}

.relation-single-object div {
    margin-left: 1.25rem;
}

.incident-other-objects {
    margin-left: 2rem;
}

.incident-other-objects-header {
    margin: .5rem 0 0 1.5rem;
    color: #86878B;
    font-weight: bold;
}

.delete-project-container {
    margin-left: 1rem;
    display: inline-block;
}

/* Responsiveness */
@media only screen and (max-width: 1050px) {
    .timeline-btn-container {
        position: relative;
        text-align: left;
        margin-bottom: .5rem;
    }

    .details-table-container-content {
        flex-direction: column;
    }

    .details-table-container-header {
        padding: 0 1.5rem;
    }

    .centered > .details-table {
        width: 100%;
    }
    
    .centered > .details-table .details-key,
    .centered > .details-table .details-value {
        padding: .4rem 1rem;
    }

    .details-key, .details-value {
        width: 50%;
    }

    .details-related-info-container {
        margin: 1rem 0 0 0;
    }

    .details-description,
    .details-related-info-container > div,
    .w60p {
        width: 100% !important;
    }

    .details-description,
    .details-related-info-container-content {
        padding: 1rem 1.5rem;
    }

    .datering-container {
        margin: 1rem -1.5rem;
    }
}