.gallery-thumbnails {
    margin: 0 2rem;
    max-height: 500px;
    overflow: auto;
}

.gallery-thumbnails button {
    border: 3px solid #73BCBC;
    height: auto;
    margin: 1rem;
    padding: 0;
}

.gallery-thumbnails button:hover {
    border: 3px solid #1890FF;
}

.gallery-selectedPhoto {
    margin: -4px 2rem 1rem 2rem;
    padding-top: 1rem;
    border-top: 4px solid #E0EFEF;
    text-align: center;
}

.gallery-selectedPhoto img {
    max-width: 100%;
}

.gallery-selectedPhoto .details-table-container-content {
    padding: 0.25rem 1rem 1rem 1rem;
}

.gallery-selectedPhoto > a {
    border: 3px solid #73BCBC;
    display: inline-block;
    height: auto;
}

.gallery-selectedPhoto > a:hover {
    border: 3px solid #1890FF;
}

.gallery-selectedPhoto a:hover {
    text-decoration: underline;
}

.photo-metadata {
    padding: .75rem .5rem .25rem .5rem;
    background-color: white;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    color: black;
}

.photo-additional-info {
    margin: 1rem 3rem;
}

.fotono-btn {
    text-decoration: underline;
    color: #06504F;
}

/* Responsiveness */
@media only screen and (max-width: 1050px) {
    .gallery-thumbnails {
        margin: 0 .5rem;
    }
}
