.loading-container {
    z-index: 50;
}

.lds-ring {
    display: inline-block;
    position: fixed;
    top: 50%;
    left: 50%;
    margin-left: -60px;
    margin-top: -60px;
}
.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 120px;
    height: 120px;
    margin: 1px;
    border-radius: 50%;
    box-shadow: inset 0 0 0 1px rgba(0,160,255,.7);
    animation: lds-ring 1.2s infinite linear;
    -webkit-mask-image: -webkit-linear-gradient(top, rgba(0,0,0,1), rgba(0,0,0,0));
    mask-image: -webkit-linear-gradient(top, rgba(0,0,0,1), rgba(0,0,0,0));
}
.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}
@keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}