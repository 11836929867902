.alert-box {
    width: 200px;
    height: 225px;
}

.alert-icon {
    height: 50px;
}

.alert-btn-placeholder {
    line-height: 1.499;
    height: 24px;
}
