.green-btn {
    color: #0C7978;
    border-color: #73BCBC;
}

.export-btn {
    background-color: white !important;
    color: #0C7978 !important;
    border-radius: 5px !important;
    border-color: transparent !important;
    margin: 2px;
    text-align: left !important;
}

.export-btns {
    position: absolute !important;
    right: 1rem;
    margin-top: -1.5rem;
    display: flex;
    flex-direction: column;
}

.resultlist {
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
}

.result-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex-grow: 1;
}

.result-container-scroll {
    display: flex;
    flex-direction: column;
    overflow: auto;
    flex-grow: 1;
}

.resultlist-header {
    position: relative;
}

.resultlist-search-info {
    padding: 1rem;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.resultlist-search-info-content {
    background: #FAFDFD;
    box-shadow: inset 0px 0px 4px rgba(154, 154, 154, 0.5);
    border-radius: 5px;
    width: 75%;
    padding: .5rem 0;
    max-height: 5rem;
    overflow-y: auto;
}

.resultlist-search-info-content button {
    color: #0C7978 !important;
    font-size: 12px;
    padding: 0 15px;
    height: 28px;
}

.resultlist-search-info-content b {
    margin-left: .25rem;
}

.resultlist-search-info-content .anticon {
    color: #979797 !important;
    margin-left: .5rem;
}

.resultlist-hits-summary {
    font-size: 12px;
    line-height: 14px;
    color: #0C7978;
    text-align: center;
    margin-top: .25rem;
}

.maxhitsdd {
    margin: 0 .5rem !important;
    background-color: #FAFDFD !important;
    padding: 0 7px !important;
    height: 25px !important;
}
.headerlistdiv{
    top: 0;
    position: sticky;
    background: #73BCBC;
    z-index: 100;
    border-bottom: 4px solid #E0EFEF;
    margin-bottom: 8px;
}

.resultlist-content-aded {
    /* margin-top: 10px; */
    padding-top: 5px;
    width: 100%;
    padding-left: 1rem;
}

.resultlist-content {
    /* margin-top: 10px; */
    padding-top: 5px;
    width: 100%;
    border-top: 4px solid #E0EFEF;
    padding-left: 1rem;
}

.resultlist-paging {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: .75rem;
}

.paging-btn {
    color: #6D7278 !important;
    font-size: 14px;
    line-height: 27px;
    font-weight: 400;
}

.paging-btn[disabled] {
    color: rgba(0,0,0,0.25) !important;
}

.paging {
    padding: 0 5px !important;
}

.active-page {
    color: black !important;
    font-weight: bold !important;
    background: #e8e8e8 !important;
    border-radius: 10px !important;
}

.hit-container {
    margin-right: 1rem;
}

.hit-content {
    display: flex;
    flex-direction: row;
    margin-left: .5rem;
}

.hit-letter-container {
    flex: 0 0 22px; /* grow shrink basis */
}

.hit-thumbnail {
    flex: 0 0 50px; /* grow shrink basis */
    height: 40px;
}

.hit-letter-container, .hit-thumbnail {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hit-thumbnail img {
    max-width: 100%;
    width: auto;
    max-height: 100%;
    height: auto;
}

.hit-thumbnail-icon {
    width: 100%;
    height: auto;
}

.hit-letter {
    /* background-color: #d8d8d8; */
    background-color: #73BCBC;
    font-size: 24px;
    color: #000000;
    font-family: 'Open Sans', Verdana, sans-serif;
    font-weight: 400;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.map-hit-thumbnail {
    flex: 0 0 20px; /* grow shrink basis */
    height: 40px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -.25rem;
}

/* hovedtype-spesifikke farger */
.arkeologisk-objekt {
    color: #232B99 !important;
    background-color: rgba(35,43,153,.3);
}
.topografisk-objekt {
    color: #803A0A !important;
    background-color: rgba(128,58,10,.3);
}
.omrade {
    color: #085820 !important;
    background-color: rgba(8,88,32,.3);
}
.prove {
    color: #511252 !important;
    background-color: rgba(81,18,82,.3);
}
.profil {
    color: #595959 !important;
    background-color: rgba(89,89,89,.3);
}
.rute {
    color: #FFC000 !important;
    background-color: rgba(255,192,0,.3);
}
.graveenhet {
    color: #FF7F27 !important;
    background-color: rgba(255,127,39,.3);
}
.funnenhet {
    color: #FF0000 !important;
    background-color: rgba(255,0,0,.3);
}
.prosjekt {
    color: #000000 !important;
    background-color: rgba(0,0,0,.3);
}

.thumbnail-placeholder {
    background: white;
    flex-grow: 1;
}

.hit-info,
.map-hit-info {
    flex-grow: 1;
    padding: 0 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.map-hit-info .hit-header {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.hit-header {
    font-size: 14px;
    line-height: 19px;
    color: #00594C;
    text-align: left;
}

.width-20{
    width: 20%;
}

.hit-details {
    font-size: 14px;
    line-height: 19px;
    color: #353535;
    text-align: left;
}

.hit-details-link {
    text-align: right;
}

.hit-link-btn {
    font-size: 14px;
    line-height: 17px;
    color: #0C7978;
    font-weight: bold;
}

.hit-buttons {
    flex: 0 0 75px; /* grow shrink basis */
    padding: .25rem 1rem;
}

.map-hit-buttons {
    flex: 0 0 25px; /* grow shrink basis */
    padding: .25rem 0;
}

.map-hit-buttons button {
    padding: 4px;
}

.hit-btn-lst-img {
    height: 1rem;
}

.hit-btn-map-img {
    height: 1.5rem;
}

.hitlist-help {
    position: absolute;
    top: 2.5rem;
    left: 20%;
    right: 20%;
    text-align: left;
    overflow: auto;
    max-height: 250px;
}

.grecaptcha-badge { 
    visibility: hidden; 
}

.dialog-footer {
    padding-top: 1em;
    color: lightgrey;
    font-size: 11px;
}

/* Responsiveness */
@media only screen and (max-width: 1000px) {
    .hit-info {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
    .hit-thumbnail {
        flex: 0 0 50px;
    }
    .hit-letter {
        font-size: 36px;
        width: 45px;
        height: 45px;
    }
}
@media only screen and (max-width: 450px) {
    .result-base-container {
        padding-left : 10px;
    }
    .export-btn .margin-left-small {
        display: none !important;
    }
    .result-container {
        padding-left: 0;
    }
    .resultlist-search-info {
        align-items: flex-start;
    }
    .resultlist-search-info-content {
        width: 90%;
    }
    .hitlist-help {
        left: 10%;
        right: 10%;
    }
    .resultlist-paging {
        justify-content: space-between;
    }
}