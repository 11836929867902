.app-header {
    position: fixed;
    width: 300px;
    height: 90px;
    top: 0;
    left: 0;
    padding: 15px 10px;
    background-color: #649291;
    color: white;
    z-index: 1001;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 1002;
}

.minimal {
    width: 125px;
    height: 65px;
    background-color: rgba(100,146,145,0.8);
}

.user-container {
    position: absolute;
    top: 0;
    right: 0;   
}

.login-btn {
    color: #ffffff !important;
    font-size: 16px;
    padding: 4px 10px !important;
}

.login-info {
    position: absolute;
    top: 135px;
    left: 1.5rem;
    text-align: left;
    width: 250px;
    z-index: 1001 !important;
}

.hide-title {
    display: none !important;
}

.aded-logo-small {
    display: none;
}

.aded-title {
    margin-top: 15px;
    font-size: 11px;
    font-style: italic;
}

.header-info-btn {
    position: absolute !important; 
    bottom: 7px; 
    right: -4px;
    font-size: 18px !important; 
    color: #ffffff !important;
}

.general-help {
    position: fixed;
    top: 95px;
    left: 200px;
    right: 30%;
    text-align: left;
    overflow: auto;
    max-height: 500px;
    z-index: 1001 !important;
    padding-top: 10px !important;
}

.general-info-header {
    font-weight: bold;
    margin-bottom: 5px;
}

/* Responsiveness */
@media only screen and (max-width: 1000px) {
    .app-header, .minimal {
        height: 60px;
    }
    .aded-title {
        display: none;
    }
    .general-help {
        top: 65px;
        left: 50px;
        right: 50px;
        bottom: 20px;
        max-height: none;
    }
}
@media only screen and (max-width: 450px) {
    .app-header {
        width: 100%;
        flex-direction: row;
    }
    .minimal {
        width: 100%;
        background-color: #649291;
    }
    .aded-logo-big {
        display: none !important;
    }
    .aded-logo-small {
        display: inline;
    }
    .aded-title {
        display: block;
        margin-left: .5rem;
    }
    .hide-title {
        display: block !important;
    }
    .general-help {
        top: 60px;
        left: 0;
        right: 0;
        bottom: 0;
    }
}