html body, .esri-widget {
  font-family: "Open Sans", Helvetica;
}

html, body, #root, .app {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

hr {
    border-color: #F4F4F4;
    border-style: solid;
    border-width: 2px;
    border-bottom: none;
}

input[type='number'] {
    border: none;
    border-bottom: 1px solid #73BCBC;
    border-radius: 0;
    margin: 0 .5rem;
    width: 30%;
    padding: .25rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

a {
  color: #0C7978;
}

:focus-visible {
    outline: 2px solid -webkit-focus-ring-color;
}
button:focus-visible,
a:focus-visible {
    outline: 2px solid -webkit-focus-ring-color !important;
}
input:focus-visible {
    outline: none !important;
}

:focus:not(:focus-visible)
{ 
    outline: none !important;
}

.app {
    display: flex;
}

.content-container {
    flex: 1 1 calc(100% - 300px);
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.above-map {
  z-index: 50;
}

.margin-top {
  margin-top: .5rem;
}

.margin-left {
  margin-left: 1rem;
}

.margin-right {
  margin-right: 1rem;
}

.margin-left-small {
  margin-left: .25rem;
}

.margin-right-small {
  margin-right: .25rem;
}

.margin-bottom {
  margin-bottom: .5rem;
}

.small-padding {
  padding: 2px 5px !important;
}

.no-padding {
  padding: 0;
}

.remove-element {
  display: none !important;
}

.center-content {
    width: 100%;
    text-align: center;
}

.border-bottom {
    border-bottom: 4px solid #F4F4F4;
}

.vertical-center {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.green-link-btn {
  padding: 0;
  font-size: 12px;
}

.green-link-btn span {
  text-decoration: underline;
  margin-left: 4px !important;
}

.btn-link-small {
  color: #6D7278;
  font-size: 13px;
}

.bold {
  font-weight: bold;
}

.bold-italic {
  font-weight: bold;
  font-style: italic;
}

.empty-result {
  color: #86878B;
  font-style: italic;
  margin: 1rem;
  text-align: center;
}

.error-msg {
  color: red;
}

.context-help {
    background: rgb(250,253,253);
    color: black;
    border: 1px solid #73bcbc;
    border-radius: 3px;
    padding: 1rem;
    z-index: 101;
    font-size: 14px;
    overflow: auto;
}

.shortcut-hint {
    padding: 0 .5rem;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    color: black;
    border: 1px solid #6D7278;
    background-color: #FFFCCC;
    position: absolute;
    z-index: 100000;
    width: 115px;
}

#alert-testbase {
    position: fixed;
    top: .5rem;
    left: 27rem;
    padding: .1rem 1rem;
    font-size: 1.2rem;
    font-weight: bold;
    background-color: rgba(255,255,255,.75);
    border: 2px solid rgb(224,32,127);
    z-index: 10000;
}

/* Responsiveness */
@media only screen and (max-width: 450px) {
    .content-container {
        margin-top: 60px;
    }
    .shortcut-hint {
        display: none;
    }
    #alert-testbase {
        position: fixed;
        top: 0;
        left: 40%;
        padding: .1rem 1rem;
    }
}
