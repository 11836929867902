.home {
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
    font-size: 18px;
    font-family: Arial, Helvetica, sans-serif;
}

.home > img {
    position: absolute;
}

.home-overlay {
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
    background: radial-gradient(75.13% 50% at 50% 50%, rgba(34, 34, 34, 0.209607) 0%, rgba(34, 34, 34, 0.909408) 100%);
}

.home-main {
    position: relative;
    height: 100%;
    width: 100%;
    overflow: auto;
    display: flex;
    flex-direction: row;
}

.home-main-part {
    display: flex;
    flex-direction: column;
    padding: 3rem 4rem;
    width: 50%;
}

/* Header */
.home-header {
    margin-bottom: 2em;
}

.home-header button {
    font-size: 1.5em;
    line-height: 1.12em;
    color: #DCDCDC;
    font-weight: 700;
    text-align: left;
    display: block;
    height: auto;
    padding-left: 0;
}

.home-header > img {
    margin-top: 2em;
}

/* Exclamation */
.home-exclamation {
    font-size: 2.5em;
    line-height: 1.25em;
    color: #F1F1F1;
    font-weight: 700;
    margin-bottom: .75em;
}

/* Text */
.home-text {
    background-color: #393939;
    border-radius: 16px;
    font-size: 1em;
    line-height: 1.25em;
    font-weight: 400;
    color: #DCDCDC;
    padding: 1.5em 3em;
    box-shadow: 3px 5px 17px rgba(0, 0, 0, 0.5);
}

.home-text > div {
    margin-top: 1rem;
}

.home-text a {
    color: #73BCBC;
    text-decoration: underline;
}

.home-text a:hover {
    color: #1890FF;
}

.home-citations {
    margin-top: 2rem !important;
    font-size: .75em;
}

/* Buttons */
.home-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.home-buttons button {
    position: relative;
    margin-top: 5em;
    font-size: 1em;
    width: 250px;
    height: 150px;
    text-align: left;
    background-color: #393939;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.home-buttons button:hover {
    border-color: #1890FF;
    background-color: #303030;
}

.home-buttons button:hover div {
    text-decoration: underline;
}

.home-buttons img {
    position: absolute;
    top: -2.5rem;
}

.home-button-header {
    font-size: 1em;
    line-height: 1.2em;
    color: #F1F1F1;
    font-weight: 700;
    margin-bottom: .25rem;
}

.home-button-info {
    white-space: normal;
    font-size: .75em;
    line-height: 1.2em;
    color: #F1F1F1;
    font-weight: 400;
    margin-bottom: .5rem;
}


/* Responsiveness */
@media only screen and (max-width: 1000px) {
    .home-main {
        flex-direction: column;
    }
    .home-main-part {
        width: 100%;
    }
    .home-buttons {
        margin-top: -1em;
    }
}

@media only screen and (max-width: 750px) {
    .home-main-part {
        padding: 1.5rem 3rem;
    }
    .home-header {
        margin-bottom: 1em;
    }
    
    .home-header > img {
        margin-top: 1em;
    }

    .home-exclamation {
        font-size: 1.25em;
    }

    .home-text {
        padding: 1em 2em;
        font-size: .75em;
    }
}