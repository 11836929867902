.save-search-container {
    width: 100%;
}

.save-search-container > div {
    margin-bottom: .25rem;
}

.save-search-button {
    margin-top: 10px;
    padding-left: 15px;
}

.save-search-button button {
    padding: 4px 0;
    font-size: 14px;
    font-weight: 600;
    color: #649291 !important;
}

.saved-search {
    flex-shrink: 1;
    flex-grow: 0;
    margin: 10px 10px 0 10px;
}

.saved-search-header {
    display: flex;
    justify-content: space-between;
}

.saved-search-content {
    max-height: 150px;
    overflow: auto;
    padding: 5px 10px;
    background-color: #FAFDFD;
    border-radius: 0 0 5px 5px;
    box-shadow: inset 0px 0px 4px 0px rgba(154,154,154,0.5);
}

.saved-search-header button {
    padding: 5px .5rem;
    width: 48%;
    font-size: 14px;
    font-weight: 400;
    color: #0C7978;
    display: flex;
    align-items: center;
}

.selected {
    background-color: #E0EFEF !important;
    border: 2px solid #62AFAE !important;
    border-bottom: none !important;
    font-weight: bold !important;
}

.search-row {
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
}

.search-row button {
    max-width: 75%;
}

.search-row button > div {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}