.map-container {
    height: 100%;
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    flex-direction: row;
    background-color: white;
}

.right-menu {
    display: flex;
}

.toc-btn {
    z-index: 1000;
    position: absolute !important;
    padding: 3px 8px !important;
    height: 40px !important;
    top: 1rem;
    right: .5rem;
    border-radius: 40px !important;
    border: 1px solid #649291 !important;
    background-color: rgba(255, 255, 255, 0.75) !important;
}

.toc-btn span {
    margin: 0 1rem;
    color: #353535;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
}

.toc-btn-inline {
    z-index: 1000;
    position: absolute !important;
    top: 0;
    right: 300px;
    height: 90px !important;
    padding: 0 5px !important;
    border: none !important;
    background-color: rgba(255,255,255,0.7) !important;
    border-radius: 2px 0 0 2px !important;
    /* border: 1px solid #649291 !important;
    border-right: 0 !important; */
    box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.25) !important;
}

.toc-container {
    position: relative;
    background-color: white;
    border: 1px solid #e2e2e2;
    box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.3);
    width: 300px;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.toc-container-header {
    background-color: #E0EFEF;
    color: #0C7978;
    font-size: 16px;
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 1rem;
}

.toc-scrollable-content {
    overflow-y: auto;
    height: 100%;
}

.toc-help {
    position: absolute;
    top: 40px;
    left: 20px;
    right: 20px;
}

.basemap-container {
    padding: 2px 8px 2px 9px;
    text-align: center;
}

.basemap-container > div {
    background-color: white;
    border-bottom: 1px solid rgba(110,110,110,0.3);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.basemap-container > div > div {
    display: flex;
    flex-direction: column;
}

.basemap-container-btn {
    width: 100% !important;
    height: 43px !important;
    padding: 12px 12px 12px 6px !important;
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    border: none !important;
    border-left: 3px solid transparent !important;
}

.basemap-container-btn img {
    padding: 0 8px 0 3px;
}

.basemap-btn {
    height: 91px !important;
    width: 226px !important;
    padding: 0 !important;
    margin-bottom: 1rem !important;
    border-radius: 3px !important;
    border: 3px solid white !important;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1) !important;
    position: relative;
}

.basemap-btn > div {
    position: absolute;
    bottom: 0;
    font-size: 14px;
    width: 100%;
    text-align: center;
    padding-top: 3px;
    background-color: rgba(255, 255, 255, 0.8);
}

.basemap-btn > div > div {
    position: absolute;
    right: 10px;
    bottom: 3px;
}

.circle {
    border: 1px solid #73BCBC;
    height: 1.2em;
    width: 1.2em;
    border-radius: 50%;
}

.gray {
    color: #C4C4C4 !important;
    border-color: #949494;
}

.blue {
    color: #1890FF !important;
    position: absolute;
    right: 10px;
    bottom: 3px;
}

.inline {
    display: inline-block;
    margin-right: .3rem;
    height: 1.1em;
    width: 1.1em;
}

.selected-basemap {
    border: 3px solid #1890FF !important;
    font-weight: bold !important;
}

.map-split-view {
    height: 50% !important;
}

.map-hidden {
    height: 0 !important;
}

.hover-tooltip {
    position: fixed;
    padding: 0 .5rem .25rem .5rem;
    background-color: white;
    border: 1px solid #e2e2e2;
    box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.3);
    z-index: 1000;
}

.map-help-btn,
.full-extent-btn, 
.expand-widgets-btn {
    height: 40px !important;
    width: 40px !important;
    background-color: #649291 !important;
    border-radius: 20px !important;
    padding: 0 !important;
    border: 1px solid #649291;
}

.map-help-btn {
    position: absolute !important;
    bottom: 165px;
    right: 15px;
    color: white;
    font-size: 18px;
    font-weight: 600;
}

.full-extent-btn {
    position: absolute !important;
    bottom: 85px;
}

.expand-widgets-btn {
    position: absolute !important;
    left: -55px;
}

.map-help-btn:hover,
.full-extent-btn:hover,
.expand-widgets-btn:hover {
    background-color: #73BCBC !important;
    border-color: #1890FF !important;
}

.map-help {
    position: absolute;
    bottom: 28px;
    right: 63px;
    max-width: 525px;
    max-height: calc(100% - 60px);
    overflow: auto;
}

/* Overriding Esri styles */
.esri-view {
    flex: 1 1 100%;
}

.esri-zoom {
    background-color: transparent;
    box-shadow: none !important;
}

.esri-widget--button {
    /* background-color: #73BCBC; */
    background-color: #649291;
    color: white;
    height: 38px;
    width: 38px;
}

.esri-zoom .esri-widget--button:first-child {
    border-bottom: none;
    margin-bottom: 1px;
    border-radius: 50% 50% 0 0;
}

.esri-zoom .esri-widget--button:last-child {
    border-radius: 0 0 50% 50%;
    border-top: none;
}

.esri-zoom .esri-widget--button:hover {
    background-color: #73BCBC !important;
    color: white !important;
    border: 1px solid #1890FF !important;
}

.esri-zoom .esri-widget--button:focus {
    outline: 2px solid -webkit-focus-ring-color;
}

.esri-legend__layer {
    overflow-x: auto;
}

.esri-view-height-less-than-medium .esri-popup__main-container {
    max-height: 35vh;
}

.esri-popup__action {
    color: #0C7978 !important;
    font-weight: bold !important;
    margin: 0 !important;
    max-width: 100% !important;
}

.esri-popup__footer {
    order: 1;
}

.esri-layer-list {
    overflow-y: hidden;
    background-color: white;
    padding-top: 0;
}

.esri-layer-list > ul > li {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.esri-layer-list__list {
    padding-top: 0;
}

.esri-ui-bottom-left {
    left: 55px;
}

.overviewDiv {
    position: absolute;
    top: 1px;
    left: 1px;
    width: 400px;
    max-width: 30%;
    height: 250px;
    max-height: 25%;
    border: 1px solid #649291;
    z-index: 1;
    /* overflow: hidden; */
    pointer-events: none;
    transition: width 0.5s, border 4s ease;
    transition-timing-function: ease-in;
}

#overviewDivMinimize {
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 50px;
    height: 50px;
    padding: 0;
    margin: 0;
    border: 0;
    pointer-events: all;
    background-color: rgba(100, 146, 145, 0.5);
    border-radius: 2px 0 0 0;
    transition: right 0.5s;
    transition-timing-function: ease-in;
}
#overviewDivMinimize:hover {
    background-color: rgba(100, 146, 145, 0.8);
}

.northarrow {
    position: absolute;
    bottom: 60px;
    right: 70px;
    width: 50px;
    height: 72px; /* *1.45 */
    z-index: 0;
    overflow: hidden;
    pointer-events: none;
}


/* Responsiveness */
@media only screen and (max-width: 1000px) {
    .toc-btn {
        background-color: transparent !important;
        border: none !important;
    }

    .toc-btn span {
        display: none !important;
    }

    .map-help-btn {
        display: none;
    }
}

@media only screen and (max-width: 450px) {
    .map-container {
        flex-direction: column;
    }
    .right-menu {
        max-height: 55%;
        position: relative;
    }
    .toc-container {
        width: 100%;
    }
    .toc-btn {
        position: fixed !important;
        top: 65px;
    }

    .toc-btn-inline {
        top: -38px;
        right: calc(50% - 21px);
        height: 42px !important;
        padding: 0 .5rem !important;
        border: none !important;
        background-color: rgba(255,255,255,0.7) !important;
        border-radius: 5px 5px 0 0;
        box-shadow: 0px -1px 5px rgba(0, 0, 0, 0.25) !important;
        transform: rotate(90deg);
    }
    .basemap-btn {
        height: 60px !important;
        width: 150px !important;
        overflow: hidden;
    }
    .basemap-btn img {
        height: 60px;
    }
    .circle {
        display: none;
    }
    .blue {
        display: none !important;
    }
    .full-extent-btn {
        bottom: 0;
    }
    .esri-widget--button {
        display: none;
    }
    .esri-scale-bar {
        margin-right: 40px;
        margin-bottom: 10px;
    }
}