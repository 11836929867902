.info {
    flex-grow: 1;
    flex-shrink: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
    box-shadow: 0 -10px 10px -10px rgba(0, 0, 0, 0.25);
    position: relative;
}

.info-container {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    overflow: hidden;
}

.info-split-view {
    height: 50% !important;
}

.info-hidden {
    height: 0 !important;
}

.info-hidden > .info-container {
    display: none;
}

.collapse-expand-container {
    position: absolute;
    top: -2rem;
    right: calc(50% - 35px);
    background-color: rgba(255,255,255,0.7);
    border-radius: 5px 5px 0 0;
    box-shadow: 0px -1px 5px rgba(0, 0, 0, 0.25);
    padding: 0 .5rem;
}

.full-expanded {
    top: 0;
    border-radius: 0 0 5px 5px;
    box-shadow: 0px -1px 5px rgba(0, 0, 0, 0.25);
}

.full-expanded button {
    height: 12px;
    padding: 0 5px !important;
}

.toggle-info-view-btn {
    height: 12px;
    padding: 0;
}

.info-header {
    position: relative;
    background-color: rgba(98, 175, 174, .2);
    color: #0C7978;
    padding: 0 1rem .25rem 2rem;
    min-height: 90px;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
}

.info-header-btns {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    min-height: 2rem;
}

.info-header-btn-img {
    height: .9em;
    margin-left: .5em;
}

@media only screen and (max-width: 450px) {

}