
/* Button */
.ant-btn-link {
    color: #0C7978;
}

.ant-btn-link:hover {
    color: #1890FF;
}

.ant-btn-link:hover span {
    text-decoration: underline;
}

.ant-btn-circle.ant-btn-lg {
    border: none;
}

.ant-input-search-button {
    color: rgba(0, 0, 0, 0.65);
    background-color: white;
    border-color: #D9D9D9;
    padding: 0 8px;
}

/* Tabs */
.ant-tabs .ant-tabs-top {
    flex-grow: 1;
}

.ant-tabs-nav-list {
    margin: 0 .5rem 1rem .5rem;
    width: 100%;
    background-color: #EAEAEA;
    display: flex;
    justify-content: space-between;
}

.ant-tabs-tab {
    padding: 5px 0;
    margin: 0 !important;
    width: 33%;
    background-color: #F4F4F4;
    display: flex;
    justify-content: center;
}

.ant-tabs-tab-btn:focus {
    outline: 2px solid -webkit-focus-ring-color;
}

.ant-tabs-tab.ant-tabs-tab-active {
    background-color: #649291 !important;
    border-bottom: 2px solid #649291 !important;
    z-index: 2;
}

.ant-tabs-tab.ant-tabs-tab-active:after {
    z-index: -1;
    position: absolute;
    top: 98.1%;
    left: calc(50% - 12px);
    content: '';
    width: 0;
    height: 0;
    border-top: solid 12px #649291;
    border-left: solid 12px transparent;
    border-right: solid 12px transparent;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: white;
}

.ant-tabs-top > .ant-tabs-nav, .ant-tabs-bottom > .ant-tabs-nav, .ant-tabs-top > div > .ant-tabs-nav, .ant-tabs-bottom > div > .ant-tabs-nav {
    margin: 0;
}

.ant-tabs-content-holder,
.ant-tabs-content {
    display: flex;
}

.ant-tabs-top > .ant-tabs-nav::before, .ant-tabs-bottom > .ant-tabs-nav::before, .ant-tabs-top > div > .ant-tabs-nav::before, .ant-tabs-bottom > div > .ant-tabs-nav::before {
    border-bottom: none;
}

/* Collapse */
.ant-collapse {
    background-color: transparent !important;
    border: none !important;
    border-top: 1px solid #F4F4F4 !important;
    text-align: left;
}

.ant-collapse > .ant-collapse-item {
    border-bottom: 1px solid #F4F4F4;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header,
.ant-collapse > .ant-collapse-item > .ant-collapse-header-unclickable {
    color: #353535;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 10px 16px 10px 20px !important;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header-unclickable {
    padding: 12px 16px 12px 20px !important;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header:focus {
    outline: 2px solid -webkit-focus-ring-color;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header:hover {
    color: #1890FF !important;
    text-decoration: underline;
}

.ant-collapse > .ant-collapse-item.filtertype-empty > .ant-collapse-header {
    color: #C0C0C0 !important;
}

.ant-collapse-content > .ant-collapse-content-box {
    padding: 0 !important;
    border-top: 1px solid #73BCBC;
}

.ant-collapse-content-box > .ant-collapse {
    border-top: none !important;
}

.filtertype-type-grouped > .ant-collapse-header {
    margin-left: 1rem !important;
    color: #353535 !important;
    background-color: white !important;
    font-weight: normal !important;
}

.filtertype-active-grouped > .ant-collapse-header {
    font-weight: bold !important;
    color: #0C7978 !important;
}

.filtertype-type-grouped > .ant-collapse-content > .ant-collapse-content-box {
    border-top: 1px solid #D8D8D8 !important;
}

/* Checkbox */
.ant-checkbox-wrapper {
    color: #6D7278;
    font-weight: 400;
    margin: .5rem;
}

.ant-checkbox-wrapper:hover {
    color: #1890FF;
    text-decoration: underline;
}

.ant-checkbox-wrapper-checked {
    color: #0C7978;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #62AFAE;
    border-color: #62AFAE;
}

/* Radio */
.ant-radio-wrapper {
    color: #6D7278;
    font-weight: 400;
    margin: .5rem;
    white-space: unset;
}

.ant-radio-wrapper:hover {
    color: #1890FF;
    text-decoration: underline;
}

.ant-radio-wrapper-checked {
    color: #0C7978;
}

.ant-radio-checked .ant-radio-inner {
    background-color: white;
    border-color: #0C7978;
}

.ant-radio-checked .ant-radio-inner::after {
    background-color: #0C7978;
}

/* Switch */
.ant-switch-checked {
    background-color: #649291;
}

/* Notification */
.ant-notification-notice {
    width: 200px !important;
    background-color: #FAFDFD;
    border: 1px solid #73BCBC;
    border-radius: 3px;;
}

.ant-notification-notice-content {
    height: 100%;
}

.ant-notification-notice-with-icon {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 1rem .5rem;
}

.ant-notification-notice-icon {
    position: relative !important;
}

.ant-notification-notice-with-icon, .ant-notification-notice-message, .ant-notification-notice-description {
    margin: 0 !important;
}

.ant-notification-notice-message {
    padding: 0 !important;
    font-weight: bold;
    text-align: center;
}

.ant-notification-notice-description {
    font-size: 13px !important;
}

.ant-notification-notice-description div {
    text-align: center;
}

/* Input */
.ant-input-number {
    border: none;
    border-bottom: 1px solid #73BCBC;
    border-radius: 0;
}

.ant-input-number-sm {
    margin: 0 .5rem;
}

/* Datepicker */
.ant-picker {
    border: none;
    border-bottom: 1px solid #73BCBC;
    border-radius: 0;
}

/* Responsiveness */
@media only screen and (max-width: 450px) {
    
}