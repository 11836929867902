.dialog {
    position: fixed;
    top: 10%;
    left: 20%;
    min-width: 375px;
    max-width: 75%;
    min-height: 150px;
    max-height: 75%;
    overflow: hidden;
    border: 1px solid #D8D8D8;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.3);
    z-index: 1002;
    display: flex;
    flex-direction: column;
}

.dialog-header {
    background-color: #649291;
    color: white;
    padding: .25rem 0 .25rem 1rem;
    height: 40px;
    cursor: pointer;
}

.dialog-header, .dialog-inner-header {
    font-weight: bold;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.dialog-header > button {
    color: white;
}

.dialog-body {
    height: calc(100% - 40px);
    background-color: #F6FAFA;
    padding: 1rem;
    overflow: auto;
    display: flex;
    flex-direction: column;
}

.dialog-info-text {
    margin-bottom: .5rem;
}

.dialog-info-text > div {
    margin-bottom: .5rem;
    max-width: 325px;
    font-size: 13px;
}

.input-caption {
    font-size: 14px;
    line-height: 16px;
    font-weight: normal;
    color: #6D7278;
    margin-top: .75rem;
}

.input-field-container {
    padding-top: .5rem;
    padding-bottom: 0.125rem;
    margin-left: -1px;
    position: relative;
}

.input-field {
    border: none !important;
    border-bottom: 1px solid #73BCBC !important;
    border-radius: 0;
}

.missing-input {
    border: 2px solid red !important;
    background-color: #FFEBEB !important;
}

.validation-msg {
    position: absolute;
    top: 2rem;
    left: .25rem;
    background-color: white;
    border: 1px solid #D8D8D8;
    border-radius: 5px;
    padding: 2px 10px 2px 5px;
}

.validation-msg > div {
    font-weight: bold;
    width: 1.25rem;
    height: 1.25rem;
    background-color: orange;
    border: 1px solid #E19100;
    border-radius: 4px;
    color: white;
    display: inline-block;
    text-align: center;
    margin-right: .5rem;
    margin-top: 2px;
}

.dialog-btn-container {
    margin-top: 2rem;
    display: flex;
    justify-content: flex-end;
}

.admin-btn-container {
    margin-top: 1rem;
}

.admin-btn-container > button, 
.dialog-btn-container > button, 
.dialog-btn-container > span > div > span > button {
    background-color: #649291;
    border-color: #06504F;
    color: white;
}

.admin-btn-container > button:hover, 
.dialog-btn-container > button:hover, 
.dialog-btn-container > span > div > span > button:hover {
    background-color: #73BCBC;
    border-color: #649291;
    color: white;
}

.date-container {
    display: flex;
    justify-content: space-between;
}

.date-container-inner {
    padding: 5px;
}

.dialog-table {
    flex: 1 1 100px;
    position: relative;
    border-collapse: separate; 
    border-spacing: 1px 0;
    overflow: auto;
}

.dialog-table th, .dialog-table td {
    padding: .5rem 1rem;
    border-bottom: 1px solid #E0EFEF;
}

.dialog-table th {
    font-size: .8em;
    color: #06504F;
    background-color: #E0EFEF;
}

.dialog-table td {
    background-color: rgba(255, 255, 255, 0.8);
    word-break: break-word;
}

.inner-dialog {
    flex: 1 1 100px;
}

.dialog-inner-header {
    margin-top: 1rem;
    padding-left: 1rem;
    height: 34px;
    color: #06504F;
    font-size: .8em;
    background-color: #E0EFEF;
}

.dialog-inner-body {
    padding: .5rem 1rem 1rem 1rem;
    background-color: rgba(255, 255, 255, 0.8);
    border-bottom: 1px solid #E0EFEF;
}

.place-bottom {
    margin-top: 0;
    align-items: flex-end;
}

.import-failed {
    color: red;
}

.sorted-by {
    color: #E0207F !important;
}

.print-dd {
    padding-left: 0!important;
}

.print-settings {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.print-setting .ant-radio-button-wrapper {
    margin-top: .5rem;
}

.print-setting .ant-radio-button-checked {
    background-color: #649291;
    border-color: #06504F;
}

.print-setting-hw {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.inputhw {
    text-align: right;
}

@media only screen and (max-width: 450px) {
    .dialog {
        top: 60px;
        left: 0;
        bottom: 0;
        max-width: 100%;
        width: 100%;
        max-height: calc(100% - 60px);
        height: calc(100% - 60px);
    }
}