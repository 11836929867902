.filter-container {
    background-color: white;
    width: 300px;
    overflow: auto;
    border-top: 3px solid #F4F4F4;
}

.reset-collapse-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.remove-filters-btn,
.collapse-all-btn {
    font-size: 13px !important;
    padding: 0 10px !important;
    height: 21px !important;
}

.filtertype-container, .filtertype-container-grouped {
    text-align: left;
    margin: 0;
    color: #0C7978;
}

.filtertype-container-grouped {
    margin-left: 1.75rem;
}

.values-container {
    margin: 0 .5rem .5rem .5rem;
    padding-top: .35rem;
    background-color: #FAFDFD;
    border-radius: 0 0 5px 5px;
    box-shadow: inset 0px 0px 4px 0px rgba(154,154,154,0.5);
}

.values-header {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #C4C4C4;
    padding: .5rem 1rem .5rem .5rem;
    color: #6D7278;
}

.values-header .ant-checkbox-wrapper, 
.values-header .ant-radio-wrapper {
    color: #0C7978;
    font-weight: 600;
    margin: 0;
}

.values-header .ant-checkbox-checked .ant-checkbox-inner {
    background-color: white;
    border: 2px solid #62AFAE;
}

.values-header .ant-radio-checked .ant-radio-inner {
    background-color: white;
    border-color: #62AFAE;
}

.values-header .ant-checkbox-inner::after,
.values-header .ant-radio-inner::after {
    border-color:#62AFAE !important;
}

.scrollable-container {
    overflow: auto;
    max-height: 12.5rem;
}

.filter-value-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.filter-value-row > a {
    flex: 0 0 25px;
    font-size: 0.7rem;
    text-decoration: underline;
}

.filtertype-type {
    font-size: 14px;
    line-height: 18px;
    font-weight: normal;
    color: #0C7978;
}

.filtertype-type-grouped {
    font-size: 14px;
    line-height: 16px;
    font-weight: normal;
    color: #6D7278;
    border-bottom: 1px solid #D8D8D8 !important;
}

.filter-nopanel-values {
    background-color: #F6FAFA;
    padding-top: .125rem;
    padding-bottom: 0.125rem;
    margin-top: .75rem;
    padding-left: .5rem;
}

.subfiltertype {
    font-size: 14px;
    line-height: 16px;
    font-weight: normal;
    color: #6D7278;
}

.filtertype-active {
    position: relative;
}

.filtertype-active:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 8px;
    border-right-color: #62AFAE;
    border-top-color: #62AFAE;
}

/* .collapsed {
    background-image: url("../../images/filter");
    background-repeat: no-repeat;
    background-position-x: right;
    cursor: pointer;
} */

.filtertype-btn {
    border: none;
    background-color: transparent;
    color: #6D7278;
    margin: .2rem .3rem;
    padding: .25rem;
    font-size: 13px;
    outline: none;
    text-align: left;
    cursor: pointer;
}

.filtertype-btn:hover {
    color: #1890FF;
}

.filtertype-btn:hover span {
    text-decoration: underline;
}

.filtertype-btn.active {
    color: #0C7978;
    font-weight: bold;
}

.removable-filter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: .5rem 16px 0 1rem;
}

.remove-filter-btn {
    height: 1.1em !important;
    font-size: 1.2em !important;
    line-height: 1.1em !important;
    padding: 0 !important;
}

.range-filter-container {
    margin: 0 1px 0 0;
    padding: 9px;
}

.range-input-error {
    border: 2px solid red;
    position: relative;
}

.range-input-error:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 8px;
    border-right-color: red;
    border-top-color: red;
}

.expand {
    font-size: 1.1em;
    margin-right: .3rem;
}

.advanced-filters {
    text-align: center;
    margin: 1rem 0;
}

.advanced-filters-btn {
    border-bottom: 3px solid #F4F4F4;
}
.advanced-filters-btn button {
    background-color: #E0EFEF !important;
    color: #0C7978 !important;
    border-color: #E0EFEF !important;
    margin-bottom: 1rem;
}

.advanced-filters-btn button:hover {
    border-color: #1890FF !important;
}

.filter-type-icon {
    margin-right: 1rem;
    font-size: 24px;
    color: #649291 !important;
}

.filter-input-icon {
    margin-right: 0;
    font-size: 20px;
    color: #649291 !important;
}

.filter-filter-input {
    float: right;
    flex-grow: 1;
    margin-top: -5px;
    margin-left: 0;
    margin-right: 5;
    padding: 4px 5px;
}

.values-header > *:not(.filter-filter-input) {
    flex-shrink: 0; /* Prevent other elements from shrinking */
}

/* Responsiveness */
@media only screen and (max-width: 450px) {
    .filter-help {
        top: 6rem;
    }
}